@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
$mainColor: #52ff00;
$black: #101113;

.testimonial-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $black; /* Adjust the background color as needed */
  flex-direction: column;

  .heading {
    font-size: 60px;
    font-weight: bold;
    font-weight: 500;
    color: black;
  }

  p {
    color: $mainColor;
    font-size: 20px;
    text-align: center;
    margin: 0;
  }

  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 50px;
    margin: 0;
    color: white;
    span {
      color: $mainColor;
    }
  }

  .testimonialContainer {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .testimonial-box {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 300px;
    height: 300px;
    margin: 0 10px;
    transition: transform 0.3s ease;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    &:hover {
      transform: translateY(-10px); /* Lift the box on hover */
    }

    .quote-icon {
      font-size: 34px;
      margin-bottom: 10px;
      color: black; /* Adjust the color as needed */
    }

    .testimonial-text {
      font-size: 16px;
      margin-bottom: 20px;
      color: #333; /* Adjust the color as needed */
    }

    .star-rating {
      color: orange;
    }

    .author-info {
      color: black; /* Adjust the color as needed */

      .author-name {
        font-size: 18px;
        margin-bottom: 5px;
        color: black;
      }

      .author-position {
        font-size: 14px;
        color: black;
      }
    }
  }
}
@media (max-width: 768px) {
  .testimonial-section {
    align-items: center;
    text-align: center;
    h2 {
      font-size: 35px;
      margin-bottom: 10px;
      width: 80%;
    }

    .testimonialContainer {
      width: 80%;
      display: flex;
      flex-direction: column;
    }

    .testimonial-box {
      width: 90%;
      padding: 0 20px;
    }
  }
}
