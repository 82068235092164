$main-color: #6100ff;

.footerParent {
  background-color: white;
  padding: 30px;
  font-size: 16px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .footerContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 100px;
    width: 80%;

    .footerColumn {
      margin-bottom: 20px;

      .footerHeading {
        font-size: 20px;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 10px;
      }
    }
  }

  .socialMediaIcons {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;

    svg {
      fill: black;
      height: 30px;
      width: 30px;
      margin-right: 10px;
      transition: all 0.2s ease-in-out;

      &:hover {
        fill: $main-color;
        transform: scale(1.2);
        cursor: pointer;
      }
    }
  }

  .divider {
    border-top: 0.5px solid #ddd;
    margin: 30px 0;
    width: 80%;
  }

  .footerText {
    margin: 0;
    color: $main-color;
  }

  .emailInput {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ccc;
  }

  .submitButton {
    background-color: $main-color;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      background-color: #646464;
    }
  }
}

@media (max-width: 1200px) {
  .footerParent .footerContainer {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    width: 80%;
  }
}

@media (max-width: 900px) {
  .footerParent .footerContainer {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .footerParent .footerContainer {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
