.testimonial-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* Adjust the background color as needed */
  flex-direction: column;
  font-family: "Oswald", sans-serif;

  .testi-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 80px 0;
    width: 80%;

    @media (max-width: 800px) {
      margin: 50px 0;
    }

    .border {
      border-bottom: 4px solid transparent;
      border-image: linear-gradient(to right, #ff006a, #6100fe);
      border-image-slice: 1;
      width: 500px;
      margin-bottom: 50px;

      @media (max-width: 1200px) {
        width: 400px;
      }

      @media (max-width: 800px) {
        width: 300px;
      }

      @media (max-width: 500px) {
        margin-bottom: 30px;
        width: 200px;
      }
    }

    p {
      font-size: 30px;
      margin: 0;
      text-align: center;
      color: black;

      @media (max-width: 1200px) {
        font-size: 25px;
      }

      @media (max-width: 800px) {
        font-size: 20px;
      }
    }
    h2 {
      font-size: 40px;
      margin: 0;
      text-align: center;
      color: black;

      @media (max-width: 1200px) {
        font-size: 30px;
      }
      @media (max-width: 800px) {
        font-size: 25px;
      }
    }
  }

  .testimonialContainer {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .photo-testimonial-box {
    border: 2px solid black;
    padding: 20px;
    text-align: center;
    width: 300px;
    height: 300px;
    margin: 0 10px;
    transition: transform 0.3s ease;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    &:hover {
      transform: translateY(-10px); /* Lift the box on hover */
    }

    .quote-icon {
      font-size: 34px;
      margin-bottom: 10px;
      color: black; /* Adjust the color as needed */
    }

    .testimonial-text {
      font-size: 16px;
      margin-bottom: 20px;
      color: #333; /* Adjust the color as needed */
    }

    .star-rating {
      color: orange;
    }

    .author-info {
      color: black; /* Adjust the color as needed */

      .author-name {
        font-size: 18px;
        margin-bottom: 5px;
        color: black;
      }

      .author-position {
        font-size: 14px;
        color: black;
      }
    }
  }
}
@media (max-width: 768px) {
  .testimonial-section {
    align-items: center;
    text-align: center;
    h2 {
      font-size: 35px;
      margin-bottom: 10px;
      width: 80%;
    }

    .testimonialContainer {
      width: 80%;
      display: flex;
      flex-direction: column;
    }

    .photo-testimonial-box {
      width: 90%;
      padding: 0 20px;
    }
  }
}
