$mainColor: #52ff00;
$black: #101113;

.about-us {
  text-align: center;
  background-color: #101113;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 200px;
  h2 {
    font-size: 50px;
    margin-bottom: 50px;
    color: white;
    span {
      color: $mainColor;
    }
  }

  .about-us-boxes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    align-items: center;

    .about-us-box {
      border-radius: 20px;
      text-align: center;
      box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
      width: 250px;
      height: 250px;
      padding: 20px;
      margin: 20px;
      background-color: transparent;
      background-color: white;
      position: relative;
      transition: transform 0.3s ease;
      cursor: pointer;
      margin-top: 100px;

      &:hover {
        transform: translateY(-10px); /* Translate the box upward on hover */
      }

      .aboutUsIcons {
        -webkit-box-align: center;
        position: absolute;
        left: 50%;
        top: -40px;
        transform: translateX(-50%);
        padding: 25px;
        color: black;
        background-color: $mainColor;
        border-radius: 22px;
        font-size: 50px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      }

      h3 {
        font-size: 22px;
        margin-top: 60px;
        margin-bottom: 10px;
      }

      p {
        font-size: 18px;
        margin: 0;
      }
    }
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 40px;
      margin-bottom: 10px;
      width: 80%;
    }

    .about-us-boxes {
      width: 90%;
      .about-us-box {
        h3 {
          font-size: 22px;
          margin-top: 60px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }
  @media (max-width: 400px) {
    h2 {
      font-size: 35px;
      margin-bottom: 10px;
      width: 80%;
    }
  }
}
