.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  background-color: black;

  .container {
    background-image: url("./images/full1.jpg");
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 500px) {
      height: 600px;
    }

    .about-content {
      width: 80%;
      color: white;
      @media (max-width: 650px) {
        width: 90%;
      }

      h2 {
        font-size: 30px;
        margin-bottom: 10px;
        text-align: center;

        @media (max-width: 1200px) {
          font-size: 25px;
        }

        @media (max-width: 800px) {
          font-size: 23px;
        }

        @media (max-width: 650px) {
          font-size: 22px;
        }
      }

      p {
        font-size: 20px;
        line-height: 1.5;
        text-align: center;

        @media (max-width: 1200px) {
          font-size: 18px;
        }

        @media (max-width: 800px) {
          font-size: 16px;
        }
        @media (max-width: 650px) {
          font-size: 15px;
        }

        @media (max-width: 500px) {
          font-size: 14px;
        }
      }
    }
  }
}
