.photo-contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: "Oswald", sans-serif;

  @media (max-width: 550px) {
    padding: 0;
  }

  .contact-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 80px 0;
    width: 80%;

    @media (max-width: 800px) {
      margin: 50px 0;
    }

    .border {
      border-bottom: 4px solid transparent;
      border-image: linear-gradient(to right, #ff006a, #6100fe);
      border-image-slice: 1;
      width: 500px;
      margin-bottom: 50px;

      @media (max-width: 1200px) {
        width: 400px;
      }

      @media (max-width: 800px) {
        width: 300px;
      }

      @media (max-width: 500px) {
        margin-bottom: 30px;
        width: 200px;
      }
    }

    h2 {
      font-size: 40px;
      margin: 0;
      text-align: center;
      color: black;

      @media (max-width: 1200px) {
        font-size: 30px;
      }
      @media (max-width: 800px) {
        font-size: 25px;
      }
    }
  }

  .contactContainer {
    display: flex;
    width: 80%;
    gap: 20px;
    margin: 0;
  }

  .heading {
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 50px;
    font-weight: 500;
    margin-bottom: 100px;
    position: relative;

    div {
      position: absolute;
      height: 80px;
      width: 80px;
      background-color: #c2acff;
      top: 0;
      left: -20px;
      z-index: -1;
      border-radius: 50%;
    }
  }

  .mapContainer {
    width: 50%;
    height: 500px;
    margin-bottom: 20px;
  }

  .contactForm {
    width: 50%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .formRow {
      display: flex;
      margin-bottom: 10px;
      gap: 20px;
    }

    .formColumn {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-right: 10px;

      label {
        font-weight: bold;
        margin-bottom: 5px;
        color: black;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"] {
        width: 100%;
        height: 40px;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid lightgray;
        outline: none;
      }

      textarea {
        width: 100%;
        height: 150px;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid lightgray;
        resize: none;
      }
    }
    .messageRow {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      label {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 18px;
        color: black;
      }

      textarea {
        width: 96%;
        height: 150px;
        padding: 10px;
        border: 2px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        resize: vertical; // Allow vertical resizing of the textarea
        transition: border-color 0.3s ease;

        &:focus {
          border-color: #007bff; // Change border color when focused
          outline: none;
        }
      }
    }

    .submitButton {
      margin-top: 10px;
      padding: 12px 16px;
      width: 100px;
      background: black;
      color: white;
      border: none;
      font-weight: bolder;
      cursor: pointer;

      &:hover {
        background: linear-gradient(to top left, #ff006a, #6100fe);
      }
    }
  }

  .contactInfo {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 25px;
      margin-bottom: 30px;
    }

    .info {
      display: flex;
      gap: 100px;
      margin-bottom: 100px;

      svg {
        font-size: 20px;
      }

      p {
        font-size: 20px;
      }
    }

    .infoRow {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      svg {
        margin-right: 10px;
      }
    }

    .socialIcons {
      svg {
        font-size: 40px;
        margin: 0 25px;
      }
    }
  }

  @media (max-width: 1160px) {
    .contactContainer {
      flex-direction: column;
    }

    .mapContainer {
      width: 100%;
    }

    .contactForm {
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    .info {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 550px) {
    .contactForm {
      .formRow {
        flex-direction: column;
      }
    }
  }
}
