// productsSection.scss
.products-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f8f8f8; // Adjust the background color as needed
  justify-content: center;
}

.productContainer {
  display: flex;
  align-items: center;
  width: 80%;
  height: 700px;
  gap: 50px;

  .left-section {
    width: 50%;
    position: relative;
  }

  .image-box {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 500px;
    border-radius: 20px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; // This ensures the image fits without distortion
    display: block;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.3
    ); // Adjust the overlay color and opacity as needed
  }

  .right-section {
    width: 50%;
  }

  h2 {
    font-size: 24px;
    margin: 10px 0;
  }

  h3 {
    font-size: 35px;
    margin: 0;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
  }

  button {
    background-color: black;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
    transition: background-color 0.3s, box-shadow 0.3s;
    margin-top: 10px;
  }
}
@media (max-width: 1000px) {
  .productContainer {
    width: 90%;
    height: 100%;
    margin-bottom: 50px;
  }
}
// Responsive adjustments
@media (max-width: 824px) {
  .productContainer {
    flex-direction: column;
    margin-top: 50px;
    width: 90%;
    .left-section {
      width: 100%;
    }

    .right-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 30px;
    }

    p {
      font-size: 16px;
    }

    button {
      padding: 15px;
      font-size: 15px;
      width: 110px;
    }
  }
}
