.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: "jost", sans-serif;
}

.contactContainer {
  display: flex;
  width: 100%;
  gap: 100px;
  margin-bottom: 100px;
}

.heading {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 50px;
  font-weight: 500;
  margin-bottom: 100px;
  position: relative;
}

.heading div {
  position: absolute;
  height: 80px;
  width: 80px;
  background-color: #c2acff;
  top: 0;
  left: -20px;
  z-index: -1;
  border-radius: 50%;
}

.mapContainer {
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

.contactForm {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.formRow {
  display: flex;
  margin-bottom: 10px;
}

.formColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  width: 100%;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid lightgray;
  outline: none;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid lightgray;
}

textarea {
  resize: none;
}

.submitButton {
  margin-top: 10px;
  padding: 12px 16px;
  width: 100px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.45);
}

.contactInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactInfo h3 {
  font-size: 25px;
  margin-bottom: 30px;
}

.info {
  display: flex;
  gap: 100px;
  margin-bottom: 100px;
}

.info svg {
  font-size: 20px;
}

.info p {
  font-size: 20px;
}

.infoRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.infoRow svg {
  margin-right: 10px;
}

.socialIcons svg {
  font-size: 40px;
  margin: 0 25px;
}

@media (max-width: 1600px) {
  .heading {
    font-size: 50px;
  }
}

@media (max-width: 1160px) {
  .contactContainer {
    flex-direction: column;
  }

  .info {
    flex-wrap: wrap;
    gap: 50px;
  }
}

@media (max-width: 1000px) {
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .aboutUs h1 {
    font-size: 40px;
  }

  .heading div {
    height: 60px;
    width: 60px;
  }
}

@media (max-width: 600px) {
  .contactInfo h3 {
    font-size: 22px;
  }

  .info svg {
    font-size: 15px;
  }

  .info p {
    font-size: 17px;
  }

  .socialIcons svg {
    font-size: 30px;
    margin: 0 20px;
  }
}

@media (max-width: 450px) {
  .heading {
    font-size: 35px;
  }

  .heading div {
    height: 50px;
    width: 50px;
  }
}

@media (max-width: 350px) {
  .contactInfo h3 {
    font-size: 15px;
  }

  .info svg {
    font-size: 12px;
  }

  .info p {
    font-size: 15px;
  }

  .info {
    gap: 10px;
  }

  .socialIcons svg {
    font-size: 25px;
    margin: 0 10px;
  }
}
