$mainColor: #52ff00;
$black: #101113;

.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: $black;

  @media (max-width: 550px) {
    padding: 0;
  }

  h2 {
    font-size: 50px;
    margin: 0;
    color: white;
    span {
      color: $mainColor;
    }

    @media (max-width: 768px) {
      font-size: 35px;
      margin-bottom: 10px;
      width: 80%;
      text-align: center;
    }
  }

  .contactContainer {
    display: flex;
    width: 80%;
    gap: 20px;
    margin-bottom: 100px;
    margin-top: 60px;
  }

  .heading {
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 50px;
    font-weight: 500;
    margin-bottom: 100px;
    position: relative;

    div {
      position: absolute;
      height: 80px;
      width: 80px;
      background-color: #c2acff;
      top: 0;
      left: -20px;
      z-index: -1;
      border-radius: 50%;
    }
  }

  .mapContainer {
    width: 50%;
    height: 500px;
    margin-bottom: 20px;
  }

  .contactForm {
    width: 50%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .formRow {
      display: flex;
      margin-bottom: 10px;
      gap: 20px;
    }

    .formColumn {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-right: 10px;

      label {
        font-weight: bold;
        margin-bottom: 5px;
        color: white;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"] {
        width: 100%;
        height: 40px;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid lightgray;
        outline: none;
      }

      textarea {
        width: 100%;
        height: 150px;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid lightgray;
        resize: none;
      }
    }
    .messageRow {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      label {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 18px;
        color: white;
      }

      textarea {
        width: 96%;
        height: 150px;
        padding: 10px;
        border: 2px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        resize: vertical; // Allow vertical resizing of the textarea
        transition: border-color 0.3s ease;

        &:focus {
          border-color: #007bff; // Change border color when focused
          outline: none;
        }
      }
    }

    .submitButton {
      margin-top: 10px;
      padding: 12px 16px;
      width: 100px;
      background-color: $mainColor;
      color: $black;
      border: none;
      border-radius: 4px;
      font-weight: bolder;
      cursor: pointer;
    }
  }

  .contactInfo {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 25px;
      margin-bottom: 30px;
    }

    .info {
      display: flex;
      gap: 100px;
      margin-bottom: 100px;

      svg {
        font-size: 20px;
      }

      p {
        font-size: 20px;
      }
    }

    .infoRow {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      svg {
        margin-right: 10px;
      }
    }

    .socialIcons {
      svg {
        font-size: 40px;
        margin: 0 25px;
      }
    }
  }

  @media (max-width: 1160px) {
    .contactContainer {
      flex-direction: column;
    }

    .mapContainer {
      width: 100%;
    }

    .contactForm {
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    .info {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 550px) {
    .contactForm {
      .formRow {
        flex-direction: column;
      }
    }
  }
}
