/* portfolio.scss */

.portfolio {
  font-family: Arial, sans-serif;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    width: 80%;
    padding: 50px;
    margin-bottom: 10px;
  }

  .logo {
    font-size: 24px;
  }

  .projects-button {
    background-color: #ec5b53;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-bottom: 50px;
  }

  .left-section {
    flex: 1;

    h2 {
      font-size: 30px;
    }

    h1 {
      font-size: 50px;
      margin: 0;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 16px;
    }

    .icons {
      display: flex;
      font-size: 40px;
      margin-top: 20px;
      gap: 20px;

      svg {
        color: black;
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 50%;
      }

      .mongodb {
        background-color: #97c86e;
      }

      .react {
        background-color: #61dafb;
        animation: rotate 5s infinite linear;
      }

      .express {
        background-color: #f28928;
      }

      .node {
        background-color: #83cd29;
      }
    }
  }

  .right-section {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .portfolio-image {
      width: 65%;
      height: auto;
      border-radius: 5px;
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media Query for Small Screens (up to 768px) */

@media (max-width: 1200px) {
  .portfolio {
    .navbar {
      width: 90%;
      padding: 40px;
    }
    .content {
      width: 90%;
    }

    .left-section {
      .icons {
        svg {
          width: 30px;
          height: 30px;
          padding: 10px;
        }
      }
    }

    .right-section {
      .portfolio-image {
        width: 70%;
      }
    }
  }
}
@media (max-width: 900px) {
  .portfolio {
    .navbar {
      width: 90%;
      padding: 30px;
    }
    .content {
      width: 95%;
      flex-direction: column-reverse;
    }

    .left-section {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 40px;
      width: 90%;
      h2 {
        font-size: 20px;
        margin: 0;
      }

      h1 {
        font-size: 35px;
        margin: 0;
      }

      h3 {
        font-size: 15px;
        margin-bottom: 0;
      }

      p {
        font-size: 14px;
        text-align: center;
      }

      .icons {
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    .right-section {
      justify-content: center;
      .portfolio-image {
        width: 45%;
      }
    }
  }
}
@media (max-width: 600px) {
  .portfolio {
    .navbar {
      padding: 20px;
    }
    .left-section {
      h2 {
        font-size: 18px;
      }

      h1 {
        font-size: 30px;
      }

      h3 {
        font-size: 13px;
      }

      p {
        font-size: 13px;
      }

      .icons {
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .right-section {
      justify-content: center;
      .portfolio-image {
        width: 55%;
      }
    }
  }
}
@media (max-width: 550px) {
  .portfolio {
    .navbar {
      padding: 10px;
    }
    .right-section {
      .portfolio-image {
        width: 60%;
      }
    }
  }
}

//450
@media (max-width: 450px) {
  .portfolio {
    .left-section {
      .icons {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .right-section {
      justify-content: center;
      .portfolio-image {
        width: 75%;
      }
    }
  }
}

@media (max-width: 400px) {
  .portfolio {
    .right-section {
      .portfolio-image {
        width: 85%;
      }
    }
  }
}
