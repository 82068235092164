.features-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 80px 0;
    width: 80%;

    @media (max-width: 800px) {
      margin: 50px 0;
    }

    .border {
      border-bottom: 4px solid transparent;
      border-image: linear-gradient(to right, #ff006a, #6100fe);
      border-image-slice: 1;
      width: 500px;
      margin-bottom: 40px;

      @media (max-width: 1200px) {
        width: 400px;
      }

      @media (max-width: 800px) {
        width: 300px;
      }
      @media (max-width: 500px) {
        margin-bottom: 30px;
        width: 200px;
      }
    }

    h2 {
      font-size: 40px;
      margin: 0;
      text-align: center;

      @media (max-width: 1200px) {
        font-size: 30px;
      }
      @media (max-width: 800px) {
        font-size: 25px;
      }
      font-family: "Oswald", sans-serif;
    }
  }

  .container {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 100px;

    @media (max-width: 1400px) {
      gap: 40px;
    }

    // @media (max-width: 700px) {
    //   flex-direction: column;
    // }

    .feature-box {
      text-align: center;
      width: 220px;
      height: 220px;
      padding: 20px;
      background-color: black;
      color: white;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-10px); /* Move up by 5 pixels on hover */
      }

      .outline {
        position: absolute;
        border: 2px solid black;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        top: 53%;
        left: 53%;
      }

      .feature-icon {
        font-size: 48px;
        color: #ff006a;
        margin-bottom: 20px;
      }

      h2 {
        font-size: 24px;
        margin: 0;
      }

      p {
        font-size: 16px;
      }
    }
  }
}
