.footerParent {
    background-color: black;
    padding: 30px;
    font-size: 16px;
    color: white;
    margin-top: 100px;
    font-family: 'jost', sans-serif;
}

.footerParent footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footerParent .footerContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 100px;
    width: 80%;
}

.footerParent .footerColumn {
    margin-bottom: 20px;
}

.footerParent .footerHeading {
    font-size: 20px;
    margin-bottom: 10px;
}

.footerParent .footerColumn p {
    margin-bottom: 10px;
}

.footerParent .socialMediaIcons {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
}

.footerParent .socialMediaIcons svg {
    fill: white;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    transition: all 0.2s ease-in-out;
}

.footerParent .socialMediaIcons svg:hover {
    fill: #c2acff;
    transform: scale(1.2);
    cursor: pointer;
}

.footerParent .divider {
    border-top: 0.5px solid #ddd;
    margin: 30px 0;
    width: 80%;
}

.footerParent .footerText {
    margin: 0;
}

.footerParent .emailInput {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ccc;
}

.footerParent .submitButton {
    background-color: #c2acff;
    color: black;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.footerParent .submitButton:hover {
    background-color: #646464;
}

@media (max-width: 1200px) {
    .footerParent .footerContainer {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 50px;
    }

    .footerParent .footerContainer {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .footerParent .footerContainer {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
    }

    .footerParent .footerContainer {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .footerParent .footerContainer {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
}

