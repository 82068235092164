.container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;
}

.leftSection {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.leftSection h2 {
    font-size: 61px;
    font-family: 'jost', sans-serif;
    font-weight: 400;
    color: white;
    line-height: 1.1;
    margin-bottom: 10px;
}

.leftSection h2 span {
    color: black;
}

.leftSection p {
    font-family: 'jost', sans-serif;
    font-size: 21px;
    color: white;
}

.leftSection .watermark {
    position: relative;
}

.leftSection .watermark img {
    position: absolute;
    bottom: 200px;
    right: -200px;
    width: 1300px;
}

.rightSection{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 40%;
}

.icons{
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    font-size: 40px;
    right: -130px;
    bottom: 0;
    color: white;
}

.icons section{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.icons div{
    position: absolute;
    height: 83%;
    width: 2px;
    background-color: white;
    bottom: 0;
}

.icons section svg {
    margin-top: 30px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .icons section svg:hover {
    transform: scale(1.2); /* Increase the scale on hover */
    color: #080808;
  }
  


.paragraph {
    margin-bottom: 10px;
}


.shopButtonContainer {
    position: relative;
}

.button {
    font-size: 30px;
    border: none;
    background-color: #080808;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.45);
    color: white;
    font-weight: 300;
    font-family: 'jost', sans-serif;
    width: 190px;
    margin-top: 50px;
    position: relative;
}

.buttonImg{
    position: absolute;
    right: -27px;
    top: -32px;
    z-index: -1;
}

.image {
    width: 100%;
    object-fit: cover;
}







/* 1600 */
@media (max-width:1600px) {

    .leftSection h2 {
        font-size:50px;
    }

    .leftSection p {
        font-size: 15px;
    }

    .button {
        font-size: 20px;
        width: 140px;
    }


    .buttonImg{
        width: 180px;
    }

    .icons{
        right: -100px;
        bottom: 0;
    }

    .icons section svg {
        margin-top: 30px;
        font-size: 40px;
    }
}





/* 1200 */
@media (max-width: 1200px) {
    .leftSection h2 {
        font-size: 45px;
    }

    .leftSection p {
        font-size: 15px;
    }

    .icons{
        right: -80px;
    }
}







/* 1000 */
@media (max-width: 1000px) {
    .leftSection h2 {
        font-size: 38px;
    }

    .leftSection p {
        font-size: 13px;
    }

    .rightSection{
        width: 50%;
    }

    .icons{
        right: -70px;
    }
}




/* 812 */
@media (max-width: 812px) {

    .leftSection h2 {
        font-size: 35px;
        text-align: center;
    }

    .leftSection p {
        font-size: 15px;
    }

    .rightSection{
        width: 55%;
    }

    .leftSection .watermark img {
        bottom: 300px;
        right: -200px;
        width: 700px;
    }

    .icons{
        right: -60px;
    }

    .icons section svg {
        margin-top: 20px;
        font-size: 35px;
    }

}


/* 750 */
@media (max-width: 750px) {
    .container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .leftSection {
        align-items: center;
    }

    .rightSection{
        margin-bottom: 30px;
        width: 70%;
    }

    .icons{
        right: -50px;
        bottom: 250px;
    }

    .icons section svg {
        font-size: 40px;
    }

}


/* 600 */
@media (max-width: 600px) {

    .leftSection h2 {
        font-size: 30px;
    }

    .leftSection p {
        font-size: 13px;
    }

    .rightSection{
        margin-bottom: 30px;
        width: 80%;
    }

    .icons{
        right: -40px;
    }

}



/* 500 */
@media (max-width: 500px) {
    .leftSection h2 {
        font-size: 27px;
        text-align: center;
    }

    .leftSection p {
        font-size: 14px;
        text-align: center;
    }

    .rightSection{
        margin-bottom: 30px;
        width: 90%;
    }

    .icons{
        right: -30px;
        bottom: 280px;
    }

    .icons section svg {
        font-size: 30px;
    }

}



/* 450 */
@media (max-width: 450px){
    .leftSection {
        width: 90%;
        text-align: center;
    }

    .leftSection .watermark img {
        bottom: 330px;
        right: -100px;
        width: 400px;
    }

    .icons{
        right: -25px;
        bottom: 280px;
    }

    .icons section svg {
        margin-top: 15px;
        font-size: 20px;
    }

}