.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Oswald", sans-serif;

  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 80px 0;
    width: 80%;

    @media (max-width: 800px) {
      margin: 50px 0;
    }

    .border {
      border-bottom: 4px solid transparent;
      border-image: linear-gradient(to right, #ff006a, #6100fe);
      border-image-slice: 1;
      width: 500px;
      margin-bottom: 50px;

      @media (max-width: 1200px) {
        width: 400px;
      }

      @media (max-width: 800px) {
        width: 300px;
      }

      @media (max-width: 500px) {
        margin-bottom: 30px;
        width: 200px;
      }
    }

    p {
      font-size: 30px;
      margin: 0;
      text-align: center;

      @media (max-width: 1200px) {
        font-size: 25px;
      }

      @media (max-width: 800px) {
        font-size: 20px;
      }
    }
    h2 {
      font-size: 40px;
      margin: 0;
      text-align: center;

      @media (max-width: 1200px) {
        font-size: 30px;
      }
      @media (max-width: 800px) {
        font-size: 25px;
      }
    }
  }
}

.insideContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 5px;

  @media (max-width: 1100px) {
    width: 90%;
  }
}

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Adjust the gap between images in a row */
  overflow: hidden;
}

.gallery-image {
  flex: 1;
  overflow: hidden;
  position: relative;
  width: calc(33.33% - 20px);
  height: 400px;
  transition: transform 0.3s ease;

  @media (max-width: 700px) {
    width: calc(10% - 5503px);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    transition: transform 0.3s ease;
  }
  &:hover {
    transform: scale(1.1); /* Increase the size by 10% (adjust as needed) */
  }
}
