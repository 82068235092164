/* projects.scss */

.ref {
  display: flex;
  align-items: center;
  justify-content: center;
  .projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin-top: 5%;
    margin-bottom: 50px;

    h2 {
      font-size: 30px;
      color: #ec5b53;
      margin-bottom: 50px;
    }

    hr {
      width: 500px;

      @media (max-width: 1000px) {
        width: 300px;
      }
      @media (max-width: 500px) {
        width: 200px;
      }
    }

    .projects {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 100px;
      width: 100%;
    }
  }

  .project-box {
    width: 20%;
    padding: 20px;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    text-align: center;

    @media (max-width: 1565px) {
      width: 35%;
    }
    @media (max-width: 1000px) {
      padding: 15px;
      width: 40%;
    }
    @media (max-width: 700px) {
      width: 80%;
      padding: 10px;
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: 5px;
    }

    h3 {
      margin-top: 10px;
      font-size: 20px;

      @media (max-width: 1000px) {
        margin: 0;
        margin-top: 10px;
      }

      @media (max-width: 400px) {
        font-size: 18px;
      }
    }

    p {
      margin-top: 10px;
      font-size: 16px;

      @media (max-width: 1000px) {
      }

      @media (max-width: 400px) {
        font-size: 14px;
      }
    }

    button {
      margin-top: 10px;
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
