.contact-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 0 auto;
  width: 100%;

  h2 {
    margin: 0;
    margin-bottom: 30px;
    color: #ec5b53;
  }
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 18px;

  @media (max-width: 600px) {
    font-size: 15px;
  }

  .info-item {
    display: flex;
    align-items: center;
    gap: 10px;
    a {
      color: #007bff;
      text-decoration: none;
      transition: color 0.3s ease;
      display: flex;
      &:hover {
        color: #ff6b6b; /* Change to your desired hover color */
        text-decoration: underline;
      }
    }
    span {
      font-weight: bold;
      color: var(--primary-color); /* Define a primary color */
      display: flex;
      align-items: center;
    }
    svg {
      font-size: 28px; /* Slightly larger icon */
      color: var(--accent-color); /* Define an accent color */
    }
  }
}
