@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
$mainColor: #52ff00;

body {
  margin: 0;
  padding: 0;
}

.mobile-menu {
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: black;
  border-radius: 5px;
  box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.5);
  padding: 10px;
  z-index: 3;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 20px;
  color: white;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
}

.mobile-menu li {
  padding: 20px;
  cursor: pointer;
}

.hamburger-menu {
  font-size: 35px;
  display: none; // Show hamburger icon
}

.header {
  background-image: url("./image/cycle.png");
  background-size: cover;
  font-family: "Roboto", sans-serif;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 20px;
  width: 90%;
  padding: 30px;
}

.navbar-left .logo {
  max-width: 100px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 70px;
  margin-right: 80px;
}

.nav-links li {
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

li:hover {
  border-bottom-color: $mainColor;
}

.contact-btn {
  background-color: $mainColor;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}

.main-section {
  width: 70%;
  display: flex;
  align-items: center;
  padding: 100px 0;
  height: 60%;
}

.info-box-container {
  position: relative;

  p {
    color: white;
    text-align: left;
    width: 400px;
    margin-left: 40px;
    font-size: 25px;

    a {
      color: white;
    }
    a:hover {
      color: $mainColor;
    }
  }
}

.info-box {
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.1)
  );
  padding: 40px;
  border-radius: 20px;
  width: 70%;
  backdrop-filter: blur(5px);
  color: white;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.6); /* Add the shadow here */
  z-index: 2; /* Set a higher z-index value for the info-box */
  position: relative;
}

.circle1 {
  width: 160px;
  height: 160px;
  background-color: $mainColor;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 25%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.circle2 {
  width: 80px;
  height: 80px;
  background-color: $mainColor;
  border-radius: 50%;
  position: absolute;
  top: 65%;
  right: 8%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.info-box h1 {
  font-size: 70px;
  margin: 0;

  span {
    color: $mainColor;
  }
}

.paragraph {
  font-size: 18px;
}

.join-btn {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  font-size: 25px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.7);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.join-btn:hover {
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.9); /* Adjust box-shadow on hover */
}

.link-short-line {
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;

  a {
    font-size: 20px;
  }
}

.icon {
  font-size: 35px;
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 205px;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;

  div {
    margin-bottom: 40px;
  }

  div:hover {
    color: $mainColor;
    cursor: pointer;
  }
}

// 1500 px
@media (max-width: 1400px) {
  .navbar {
    font-size: 18px;
    padding: 20px;
  }

  .nav-links {
    gap: 40px;
    margin-right: 40px;
  }

  .main-section {
    width: 80%;
    height: 60%;
  }

  .info-box-container p {
    width: 100%;
    margin-left: 10px;
  }

  .info-box {
    width: 70%;
    padding: 30px;
  }

  .circle1 {
    width: 120px;
    height: 120px;
    left: 25%;
  }

  .circle2 {
    width: 60px;
    height: 60px;
    top: 65%;
    right: 11%;
  }

  .info-box h1 {
    margin-top: -10px;
    font-size: 65px;
  }

  .paragraph {
    font-size: 17px;
  }

  .join-btn {
    font-size: 20px;
    margin-top: 30px;
  }

  .link-short-line a {
    font-size: 18px;
  }

  .icon {
    bottom: 20px;
    right: 30px;
    justify-content: space-between;
    width: auto;
    font-size: 30px;
  }
}

// 1000px
@media (max-width: 1000px) {
  .navbar {
    font-size: 18px;
    padding: 20px;
  }

  .nav-links {
    gap: 40px;
    margin-right: 40px;
  }

  .main-section {
    width: 100%;
    height: 55%;
    display: flex;
    display: flex;
    justify-content: center;
  }

  .info-box-container {
    width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .info-box {
    padding: 30px;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .circle1 {
    width: 120px;
    height: 120px;
    left: 35%;
  }

  .circle2 {
    width: 60px;
    height: 60px;
    top: 65%;
    right: 15%;
    right: 7%;
  }

  .info-box h1 {
    margin-top: -10px;
    font-size: 60px;
    text-align: center;
  }

  .paragraph {
    text-align: center;
  }

  .link-short-line {
    display: flex;
    justify-content: center;
  }

  .link-short-line a {
    font-size: 20px;
  }

  .icon {
    bottom: 10px;
    height: 30px;
    right: 30px;
    justify-content: space-between;
    flex-direction: row;
    font-size: 30px;
    gap: 40px;
  }

  .navbar-right {
    display: none; // Hide navbar right options
  }
  .hamburger-menu {
    display: block; // Show hamburger icon
  }
}
// 800px
@media (max-width: 800px) {
  .info-box {
    width: 75%;
  }

  .circle1 {
    left: 35%;
  }

  .circle2 {
    right: -5%;
  }
}
// 650px
@media (max-width: 650px) {
  .navbar {
    margin-top: 10px;
    padding: 10px;
  }

  .hamburger-menu {
    font-size: 30px;
  }

  .navbar-left .logo {
    max-width: 70px;
  }

  .info-box {
    width: 85%;
    padding: 30px 20px;
  }

  .circle1 {
    height: 100px;
    width: 100px;
    left: 35%;
  }

  .circle2 {
    height: 50px;
    width: 50px;
    right: -8%;
  }

  .link-short-line a {
    font-size: 14px;
  }
} // 430px
@media (max-width: 430px) {
  .circle2 {
    right: -12%;
  }
}
// 450px
@media (max-width: 450px) {
  .info-box {
    width: 90%;
    padding: 30px 20px;
  }

  .circle1 {
    height: 80px;
    width: 80px;
  }

  .circle2 {
    height: 30px;
    width: 30px;
  }

  .info-box h1 {
    font-size: 50px;
  }

  .paragraph {
    font-size: 15px;
  }
}
