.aboutUs {
  padding: 20px 0;
  font-family: "jost", sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

h1 {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 50px;
  font-weight: 500;
  position: relative;
}

h1 div {
  position: absolute;
  height: 80px;
  width: 80px;
  background-color: #c2acff;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 50%;
}

.section1 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
}

.section1 .content {
  width: 100%;
}

.section2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
}

.section2 .content {
  width: 100%;
}

.section3 .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.section4 {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 157.5%;
  /* or 39px */

  text-align: right;
  letter-spacing: 0.16em;
  text-transform: uppercase;
}

.section4 span {
  border-top: 3px solid #c2acff;
}

.imageContainer {
  z-index: -1;
}

.imageContainer img {
  max-width: 100%;
  z-index: -1;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
}

.column {
  display: flex;
  justify-content: space-between;
}

.column div {
  display: flex;
  flex-direction: column;
  width: 47%;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Responsive Styles */

/* For max-width: 1600px */
@media (max-width: 1600px) {
  .aboutUs h1 {
    font-size: 50px;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }
}

/* For max-width: 1000px */
@media (max-width: 1000px) {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 16px;
  }
}

/* For max-width: 750px */
@media (max-width: 750px) {
  h1 {
    font-size: 30px;
  }

  .aboutUs h1 {
    font-size: 40px;
  }

  h1 div {
    height: 60px;
    width: 60px;
  }
}

/* 600px  */
@media (max-width: 600px) {
  .section1 {
    flex-direction: column;
    text-align: center;
  }

  .section2 .column {
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 20px;
  }

  .section2 .column div {
    text-align: center;
    margin-bottom: 50px;
    width: 100%;
  }

  .section3 {
    text-align: center;
  }
}

@media (max-width: 450px) {
  .aboutUs h1 {
    font-size: 35px;
  }

  h1 div {
    height: 50px;
    width: 50px;
  }
}
