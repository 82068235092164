@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;600&display=swap");

.photo-website {
  display: flex;
  height: 100vh;
  font-family: "Oswald", sans-serif;
  position: relative;
  overflow: hidden;

  .mobile-menu {
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.5);
    padding: 10px;
    z-index: 3;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 20px;
    color: white;
  }

  .mobile-menu ul {
    list-style: none;
    padding: 0;
  }

  .mobile-menu li {
    padding: 20px;
    cursor: pointer;
    list-style: none;

    a {
      text-decoration: none;
      color: black;
      font-size: 25px;
    }

    button {
      background-color: black;
      border: none;
      color: white;
      padding: 10px;
      font-size: 20px;
    }
  }

  .container {
    width: 100%;
    margin: 50px;
    display: flex;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
    padding: 10px;
    backdrop-filter: blur(10px);
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.4)
    );
    position: relative;
    justify-content: center;

    .navbar {
      position: absolute;
      display: flex;
      justify-content: space-between;
      z-index: 1;
      top: 10px;
      left: 0;
      right: 0;
      margin: auto;
      align-self: center;

      .logo {
        font-size: 30px;
        color: black;
      }

      ul {
        list-style-type: none;
        display: flex;
        margin: 0;
        padding: 0;
        li {
          margin-right: 20px;
          display: flex;
          align-items: center;

          a {
            text-decoration: none;
            color: white;
            margin-right: 60px;
            font-size: 30px;

            &:hover {
              color: black;
            }
          }
        }

        button {
          background-color: black;
          color: white;
          padding: 10px;
          border: none;
          font-size: 18px;
          box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3);
          cursor: pointer;
          &:hover {
            box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }

  .left-section {
    display: flex;
    flex-direction: column;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .data {
      width: 100%;
      height: 80%;
      padding-left: 100px;
      justify-content: center;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 60px;
        margin-bottom: 10px;
        text-transform: uppercase;
        width: 70%;
      }

      p {
        font-size: 25px;
        width: 80%;
        margin-bottom: 20px;
      }

      span {
        margin-top: 40px;

        button {
          padding: 15px;
          background-color: black;
          font-size: 30px;
          color: #fff;
          border: none;
          cursor: pointer;
          box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3);

          &:hover {
            box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.5);
          }
        }
      }

      div {
        margin-top: 20px; /* Remove margin-top */
        font-size: 30px;
        display: flex;
        align-items: center;

        .icon {
          font-size: 20px;
        }
      }
    }
  }

  .right-section {
    flex: 2;
    position: relative;

    .image-background {
      height: 100%;
      background-image: url("./image/photo.png"); /* Replace with your image path */
      background-size: cover;
      background-position: center;
    }
  }

  .upperCircle {
    position: absolute;
    background-color: #ff006a;
    width: 800px;
    height: 800px;
    border-radius: 50%;
    z-index: -1;
    top: -10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .lowerCircle {
    position: absolute;
    background-color: #6100ff;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    z-index: -1;
    bottom: -25%;
    left: 20%;
    transform: translateX(-50%);
  }
}
@media (max-width: 1700px) {
  .photo-website {
    .container {
      margin: 40px;
      .navbar {
        ul {
          li {
            margin-right: 0;

            a {
              margin-right: 60px;
              font-size: 25px;
            }
          }

          button {
            padding: 10px;
            font-size: 18px;
          }
        }
      }
    }

    .left-section {
      .data {
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 18px;
          margin: 0;
        }

        span {
          margin-top: 40px;

          button {
            padding: 15px;
            font-size: 20px;
          }
        }

        div {
          margin-top: 20px; /* Remove margin-top */
          font-size: 25px;
          .icon {
            font-size: 20px;
          }
        }
      }
    }
    .upperCircle {
      width: 600px;
      height: 600px;
      top: -5%;
    }
    .lowerCircle {
      width: 300px;
      height: 300px;
      bottom: -20%;
    }
  }
}

@media (max-width: 1200px) {
  .photo-website {
    .container {
      .navbar {
        ul {
          li {
            margin-right: 0;

            a {
              margin-right: 40px;
              font-size: 25px;
            }
          }

          button {
            padding: 10px;
            font-size: 18px;
          }
        }
      }
    }

    .left-section {
      .data {
        h1 {
          font-size: 45px;
        }
        p {
          font-size: 22px;
        }

        span {
          margin-top: 40px;

          button {
            padding: 15px;
            font-size: 22px;
          }
        }

        div {
          margin-top: 20px; /* Remove margin-top */
          font-size: 23px;
          .icon {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 1028px) {
  .photo-website {
    .container {
      .navbar {
        ul {
          display: none;
        }
      }
      .hamburger-menu {
        display: block; // Show hamburger icon
      }
    }

    .left-section {
      .data {
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 20px;
        }

        span {
          margin-top: 40px;

          button {
            padding: 15px;
            font-size: 20px;
          }
        }

        div {
          font-size: 21px;
        }
      }
    }
  }
}
@media (max-width: 930px) {
  .photo-website {
    .container {
      flex-direction: column-reverse;
      margin: 35px;

      .navbar {
        .logo {
          color: white;
        }
      }
    }

    .left-section {
      width: 100%;
      .data {
        padding-left: 0;
        width: 90%;
        align-items: center;
        display: flex;
        justify-content: center;
        h1 {
          font-size: 40px;
          width: 100%;
          text-align: center;
        }
        p {
          font-size: 17px;
          width: 100%;
          text-align: center;
          margin: 0;
        }

        span {
          margin-top: 40px;

          button {
            padding: 15px;
            font-size: 20px;
          }
        }

        div {
          margin-top: 0;
          font-size: 21px;
          margin: 20px 0;
        }
      }
    }
    .upperCircle {
      width: 600px;
      height: 600px;
      top: -5%;
      left: 100%;
    }
    .lowerCircle {
      width: 300px;
      height: 300px;
      bottom: -15%;
      left: 0;
    }
  }
}
@media (max-width: 750px) {
  .photo-website {
    .container {
      .navbar {
        .logo {
          font-size: 25px;
          color: white;
        }
      }
    }
    .left-section {
      .data {
        h1 {
          font-size: 35px;
        }
        p {
          font-size: 15px;
        }

        span {
          margin-top: 40px;

          button {
            padding: 15px;
            font-size: 18px;
          }
        }

        div {
          font-size: 18px;
        }
      }
    }
    .upperCircle {
      width: 400px;
      height: 400px;
      top: 0%;
      left: 100%;
    }
    .lowerCircle {
      width: 250px;
      height: 250px;
      bottom: -15%;
    }
  }
}
@media (max-width: 435px) {
  .photo-website {
    .container {
      margin: 20px;
      .navbar {
        .logo {
          margin-left: 10px;
        }

        .hamburger-menu {
          margin-right: 10px;
        }
      }
    }
    .left-section {
      .data {
        h1 {
          font-size: 30px;
        }
        p {
          font-size: 14px;
        }

        span {
          margin-top: 40px;

          button {
            padding: 15px;
            font-size: 16px;
          }
        }

        div {
          font-size: 18px;
        }
      }
    }
  }
}
@media (max-width: 390px) {
  .photo-website {
    .container {
      margin: 25px;
    }
    .left-section {
      .data {
        width: 95%;
        h1 {
          font-size: 28px;
        }
        p {
          font-size: 14px;
        }

        span {
          margin-top: 40px;

          button {
            padding: 15px;
            font-size: 16px;
          }
        }

        div {
          font-size: 18px;
        }
      }
    }
  }
}
