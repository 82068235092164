.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.logo h1 {
    font-size: 55px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.logo .logoDot {
    background-color: black;
    width: 10px;
    height: 10px;
    position: absolute;
    right: -15px;
    bottom: 0;
    border-radius: 50%;
}

.navbarOptions {
    list-style-type: none;
    display: flex;
    gap: 20px;
}

.navbarOptions li {
    font-size: 30px;
    margin-right: 50px;
    font-family: 'jost', sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.navbarOption {
    cursor: pointer;
}

.navbarButton {
    font-size: 30px;
    border: none;
    background-color: #080808;
    padding: 5px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.3);
    color: white;
    font-weight: 300;
    font-family: 'jost', sans-serif;
}

.hamburger{
    display: none;
}

.hamburger svg{
    font-size: 30px;
}



/* 1600 */
@media (max-width: 1600px) {
    .logo h1 {
        height: 30px;
        font-size: 40px;
    }

    .navbarOptions li {
        margin-right: 20px;
        font-size: 24px;
    }

    .navbarButton {
        font-size: 20px;
    }
}

/* 1000 */
@media (max-width: 1000px) {
    .logo h1 {
        height: 25px;
        font-size: 30px;
    }

    .navbarOptions li {
        margin-right: 20px;
        font-size: 22px;
    }

    .navbarButton {
        font-size: 20px;
    }
}




/* 750 */
@media (max-width: 750px) {
    .navbar {
        flex-direction: column;
    }

    .navbarOptions {
        gap: 10px;
    }
}



/* 500 */
@media (max-width: 500px) {

    .navbar {
        flex-direction: row;
    }

    .navbarOptions li {
        font-size: 20px;
        margin-right: 0;
        border-bottom: 0.5px solid rgb(217, 217, 217);
    }

    .hamburger{
        display: flex;
    }

    .navbarOptions {
        position: fixed;
        top: 80px;
        right: -60%;
        width: 50%;
        background-color: #f7f7f7;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        transition: all 0.3s ease-in-out;
        z-index: 1;
        border-radius: 10px;
        box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.2);
    }

    .navbarButton{
        box-shadow: none;
    }

    .navbarOptions.show {
        transition: all 0.3s ease-in-out;
        right: 0;
    }

    .navbarOptions.hide {
        right: -50%;
    }
}